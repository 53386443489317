(function () {
  'use strict';

  class HtmlDialogCtrl {
    constructor($mdDialog, HtmlDialog) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.html = HtmlDialog.getObj();
    }
    cancel() {
      let vm = this;
      vm.$mdDialog.cancel();
    }
    hide() {
      let vm = this;
      vm.$mdDialog.hide();
    }
  }

  /**
   * @ngdoc object
   * @name components.controller:HtmlDialogCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('HtmlDialogCtrl', HtmlDialogCtrl);
}());
